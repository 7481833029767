<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-header mt-2 ml-2">
            <h5>Start Date</h5>
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
            <a-date-picker v-model:value="value2" :format="dateFormatList" />
          </div>
          <div class="card-body mt-2 ml-2">
          <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Fungsi">
            <a-select-option value="distributor_dua_agung">Distributor Dua Agung</a-select-option>
            <a-select-option value="distributor_erp_sisca">Distributor ERP Sisca</a-select-option>
            <a-select-option value="distributor_kwsg">Distributor KWSG</a-select-option>
            <a-select-option value="distributor_sid">Distributor SID</a-select-option>
             <a-select-option value="goffi">GOFFI</a-select-option>
             <a-select-option value="gsda">GSDA</a-select-option>
              <a-select-option value="jawa">JAWA</a-select-option>
              <a-select-option value="pmp">PMP</a-select-option>
              <a-select-option value="pos">POS</a-select-option>
              <a-select-option value="sbp">SBP</a-select-option>
              <a-select-option value="sidigi">SIDIGI</a-select-option>
              <a-select-option value="smart_bisoft_erp">Smart Bisoft ERP</a-select-option>
            </a-select>
          </div>
          <div class="card-body mt-2 ml-2">
              <filter-distributor v-model:value="distributors"></filter-distributor>
          </div>
          <div class="card-body mt-2 ml-2" style="width: 300px">
            <a-input v-model:value="value" placeholder="Cari ..." />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-header mt-2 ml-2">
            <h5>End Date</h5>
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
               <a-date-picker v-model:value="value2" :format="dateFormatList" />
          </div>
          <div class="card-body ml-2 mt-2">
          <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Type">
            <a-select-option value="penerimaan">Penerimaan</a-select-option>
            <a-select-option value="penjualan">Penjualan</a-select-option>
            <a-select-option value="stok">Stok</a-select-option>
            </a-select>
          </div>
          <div class="card-body ml-2 mt-2">
             <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Status">
            <a-select-option value="sukses">Sukses</a-select-option>
            <a-select-option value="gagal">Gagal</a-select-option>
          </a-select>
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
             <a-button type="primary" title="cari" @click="search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment, { Moment } from 'moment';
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
// import MModal from './modal'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
export default {
  components: {
    // MModal,
    // FilterRegional,
    // FilterProvince,
    // FilterArea,
    // FilterKabupatenKota,
    FilterDistributor,
  },
  setup() {
    const dateFormat = 'YYYY/MM/DD';
    const monthFormat = 'YYYY/MM';
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    return {
      value2: ref<Moment>(moment('01/01/2015', dateFormatList[0])),
      dateFormat,
      monthFormat,
      dateFormatList,
      moment,
    };
  },
};
</script>
